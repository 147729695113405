<nav class="navbar sticky-top navbar-expand-lg">
  <div class="container">
    <a class="navbar-brand" href="#"><img src="../assets/img/Checkfy_2.png" width="235px" height="150rem"></a>
    <button class="navbar-toggler border border-dark" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars "></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link button text-white" routerLink="contac">Contacto</a>
        </li>
        <li class="nav-item">
          <a class="nav-link button text-white" href="#function">Funcionamiento</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

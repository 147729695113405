  <div class="card">
    <div class="card-header">
      <div class="container">
        <div class="row icons" id="icons">
          <span class="col-3 fa fa-android"></span>
          <span class="col-3 fa fa-windows"></span>
          <span class="col-3 fa fa-apple"></span>
          <span class="col-3 fa fa-cloud"></span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="clearfix">
        <div class="row" id="attributes">
          <img class="col-lg-6 col-md col-sm-10 float-md-start mb-3 ms-md-3"
            src="../../../assets/img/attributes/pexels.png" data-aos="zoom-in" data-aos-duration="3000">
          <div class="col">
            <div class="row box" data-aos="fade-left">
              <img class="col-lg-5 col-md-3 col-sm-6" src="../../../assets/img/attributes/Atr1.png" width="50%"
                alt="Atributo 1">
              <span class="col-lg-7 col-md-9 col-sm-6 text-center">
                Nunca había sido tan fácil justificar, ahora realízalo en segundos.</span>
            </div>
            <div class="row box" data-aos="fade-left">
              <img class="col-lg-5 col-md-3 col-sm-3" src="../../../assets/img/attributes/Atr2.png" alt="Atributo 2">
              <span class="col-lg-7 col-md-9 col-sm-9 text-center">
                Despreocúpate del mal rendimiento verificando la puntualidad de tus colaboradores</span>

            </div>
            <div class="row box" data-aos="fade-left">
              <img class="col-lg-5 col-md-3 col-sm-3" src="../../../assets/img/attributes/Atr3.png" alt="Atributo 2">
              <span class="col-lg-7 col-md-9 col-sm-9 text-center">
                Prepárate para llevar a tu empresa al siguiente nivel.</span>
            </div>
          </div>
        </div>
      </div>
      <div class="cards-bottom mt-2">
        <div class="row">
          <div class="col-sm col-md mt-2" data-aos="fade-up" data-aos-duration="3000">
            <img src="../../../assets/img/attributes//bottom/foto1.png" height="80%">
            <br>
            <p>Información en tiempo real.</p>
          </div>
          <div class="col-sm col-md mt-2" data-aos="fade-up" data-aos-duration="3000">
            <img src="../../../assets/img/attributes//bottom/foto2.png" height="80%">
            <br>
            <p>Multiplataforma.</p>
          </div>
          <div class="col-sm col-md mt-2" data-aos="fade-up" data-aos-duration="3500">
            <img src="../../../assets/img/attributes//bottom/foto3.png" height="80%">
            <br>
            <p>Fácil control de faltas y retardos.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.scss']
})
export class DashComponent implements OnInit {
  vidio: string = '../../../assets//video/video1.mp4'
  title = 'checkfy-web';
  top: boolean;

  ngOnInit(): void {
    this.scrollLisener();
  }

  onTop() {
    window.scroll(0, 0);
  }

  scrollLisener() {
    window.addEventListener("scroll", () => {
      this.showTop();
    })
  }

  showTop() {
    document.documentElement.scrollTop >= 400
      ? this.top = true
      : this.top = false;
  }
}

<div class="container-fluid">
  <div class="row d-flex justify-content-center my-4">
    <div class="col-md-10 col-sm-12">
      <div class="row d-flex justify-content-center p-0">
        <div class="col-md-6 col-sm-12 d-flex flex-column text-center justify-content-center bg-color">
          <h1 class="px-0">Información de contacto</h1>
          <div class="text-center"><span class="fa fa-envelope-o fa-5x p-3"></span></div><span
            class="text-center h3">¡Escribenos!</span>
          <div class="d-flex justify-content-center"><a target="_blank" href="https://www.facebook.com/TecnofyMex"
              class="btn p-2 fa fa-facebook-official facebook"></a><a target="_blank"
              href="https://www.instagram.com/tecnofyMex/" class="btn p-2 mx-2 fa fa-instagram instagram"></a><a
              target="_blank" href="https://www.youtube.com/channel/UC5uKXc5eFzcbQFinN_eMBYw"
              class="btn p-2 fa fa-youtube-play youtube"></a></div>
        </div>
        <div class="col-md-6 col-sm-12 d-flex flex-column border py-5">
          <form>
            <div class="mb-2"><label class="form-label">Nuestro
                Teléfono</label><br><span class="fw-bold">+52 662 134
                5400</span></div>
            <div class="mb-4"><label class="form-label">Correo
                Electrónico</label><br><span class="fw-bold">contacto@tecnofy.com</span></div>
            <div class="mb-2"><a
                href="http://api.whatsapp.com/send?phone=526621345400&amp;text=Hola%2C+me+gustar%C3%ADa+m%C3%A1s+informaci%C3%B3n%2C+sobre+TECNOFY+y+sus+productos."
                target="_blank" class="btn btn-what animated tada"> Mandanos un mensaje <i aria-hidden="true"
                  class="fa fa-whatsapp fa-lg"></i></a></div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { DashComponent } from './views/dash/dash.component';
import { ContacComponent } from './views/contac/contac.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: "", component: DashComponent },
  { path: "contac", component: ContacComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="col-12 text-center mx-auto cloud-shape d-flex align-items-center justify-content-center"
      data-aos="flip-right" data-aos-duration="2000">
        <span class="h3 font-weight-bold">Consulta reportes fácilmente</span>
      </div>

      <div class="cards-bottom">
        <div class="row">
          <div class="col-sm" data-aos="fade-right">
            <img src="../../../assets/img/functions/reports/retardos.png">
          </div>

          <i class="col-1 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block
            align-self-center text-center text-center justify-content-center
            fa fa-minus" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500"></i>

          <div class="col-sm" data-aos="fade-right">
            <img src="../../../assets/img/functions/reports/retardos2.png">
          </div>

        </div>
      </div>

<div>
    <h1 class="col-6 text-justify" style="font-size:4vw;"
    data-aos="zoom-out" data-aos-duration="2000">
      Mantén control de las asistencias y retardos en tiempo real
    </h1>
    <video autoPlay muted loop oncanplay="this.play()" onloadedmetadata="this.muted = true" width="100%">
      <source [src]="vidio" type="video/mp4">
    </video>
  <app-attributes id="attributes"></app-attributes>
  <br>
  <app-function id="function"></app-function>
  <button [id]="top ? 'scrollTop':''" (click)="onTop()">
    <i class="fa fa-angle-up" aria-hidden="true"></i>
  </button>
</div>

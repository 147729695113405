<div class="card-footer">
  <div class="row d-flex justify-content-center align-items-center">
    <div class="col-md-2 col-sm-12 mb-4">
      <img class="" src="../../../assets/img/footer/Intenso sin fondo.png" height="50%" width="100%">
    </div>
    <div class="col-md-2 col-sm-12 mb-4 text-center">
      <span class="footer-title text-white fw-bold">Software desarrollado por</span>
      <br>
      <a class="text-white underline" target="_blank" href="https://tecnofy.org">Tecnofy</a>
    </div>
    <div class="col-md-2 col-sm-12 mb-4 text-center">
      <span class="footer-subtitle text-white fw-bold">Sistemas</span>
      <br>
      <a class="btn-link underline" target="_blank" href="https://www.satisfy.com.mx">Satisfy</a>
      <br>
      <a class="btn-link underline" target="_blank" href="https://www.gestify.com.mx">Gestify</a>
      <br>
      <a class="btn-link underline" target="_blank" href="https://www.pos2020.com.mx">Pos2020</a>
    </div>
    <div class="col-md-2 col-sm-12 mb-4 text-center">
      <span class="footer-subtitle text-white fw-bold underline">Legal</span>
      <br>
      <a href="#" class="underline">Termino y condiciones</a>
    </div>
    <div class="col-md-2 col-sm-12 text-center">
      <span class="footer-subtitle text-white fw-bold">Enlaces rápidos</span>
      <br>
      <a class="btn-link underline" href="#">Inicio</a>
      <br>
      <a class="btn-link underline" href="#function">Funcionamiento</a>
      <br>
      <a class="btn-link underline" href="/contac">Contacto</a>
    </div>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-10">
      <hr class="mb-1 text-white myHr">
    </div>
  </div>
  <div class="row pb-3">
    <span class="text-center mb-1 text-white fw-bold">Nuestras Redes Sociales</span>
    <div class="col-12 d-flex justify-content-center">
      <div class="row">
        <div class="col-4">
          <a class="btn-link p-1 px-2 facebook" target="_blank" href="https://www.facebook.com/TecnofyMex">
            <i class="fa fa-facebook-official" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="btn-link p-1 px-2 instagram" target="_blank" href="https://www.instagram.com/tecnofyMex/">
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </div>
        <div class="col-4">
          <a class="btn-link p-1 px-2 youtube" target="_blank"
            href="https://www.youtube.com/channel/UC5uKXc5eFzcbQFinN_eMBYw">
            <i class="fa fa-youtube-play" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AttributesComponent } from './views/attributes/attributes.component';
import { FunctionComponent } from './views/function/function.component';
import { NavbarComponent } from './views/navbar/navbar.component';
import { FooterComponent } from './views/footer/footer.component';
import { ContacComponent } from './views/contac/contac.component';
import { DashComponent } from './views/dash/dash.component';
import { ReportsComponent } from './views/function/reports/reports.component';

@NgModule({
  declarations: [
    AppComponent,
    AttributesComponent,
    FunctionComponent,
    NavbarComponent,
    FooterComponent,
    ContacComponent,
    DashComponent,
    ReportsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
